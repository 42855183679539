import { Component, h } from 'preact';
import { isEmpty } from 'lodash';

import { Task } from '../../../projects/tasks-api-axios';
import connect from '../../common/state/connect';
import { completeTask, saveTask } from '../../common/state/actions-task';
import { ApiCallStatus } from '../../common/models';
import { TaskStateInterface } from '../../common/state/store';
import { alertUtils } from '../../common/state/actions-alerts';
import { TaskCompletedEventDetail, TaskSavedEventDetail } from './SgWorkflowTaskFormComponent';
import { validate as isValidUUID } from 'uuid';
//@ts-ignore
import Analytics from '../../analytics/analytics.service';
// @ts-ignore
import i18n from '../../common/intl/intl.service.js';

interface IProps {
  task: Task;
  completeTask: (submission: any, selectedTaskId: string, selectedTaskEngineId: string) => Promise<any>;
  onTaskSaved: (detail: TaskSavedEventDetail) => {};
  onTaskCompleted: (detail: TaskCompletedEventDetail) => {};
  saveTask: (submission: any, selectedTaskId: string, selectedTaskEngineId: string) => Promise<any>;
  selectedTaskUpdateStatus: ApiCallStatus;
}

const STORE_PROPERTIES_KEYS: (keyof TaskStateInterface)[] = ['selectedTaskUpdateStatus'];

interface IState {
  isSubmitting: boolean;
  isSaving: boolean;
  submission?: any;
  checkValidity?: () => {};
  beforeSubmit?: (isDraft: boolean) => Promise<Error[]>;
}

const {
  service: { formatMessage, formatMessageAsString },
} = i18n;

const submissionChangeEvent = 'sg-workflow-form-renderer--submission-changed';

const CompleteButtonTypography = ({ isSubmitting }: any): JSX.Element => {
  if (!isSubmitting) {
    return (
      <div className='d-flex align-items-center'>
        <span>{formatMessage({ id: 'task.completeTask' })}</span>
      </div>
    );
  }

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div>
        <div className='spinner-border spinner-border-sm' style='width: 1rem; height: 1rem;' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
      <div>
        <span className='mx-2 my-2'>{formatMessage({ id: 'task.completingTask' })}</span>
      </div>
    </div>
  );
};

class TaskActionsCompleteComponent extends Component<IProps, IState> {
  constructor() {
    super();
    this.state = {
      isSubmitting: false,
      isSaving: false,
      submission: undefined,
      checkValidity: undefined,
      beforeSubmit: undefined,
    };

    this.completeTask = this.completeTask.bind(this);
    this.saveTask = this.saveTask.bind(this);
    this.submissionChangedEventHandler = this.submissionChangedEventHandler.bind(this);
  }

  submissionChangedEventHandler(event: CustomEvent): void {
    if (event && event.detail) {
      this.setState({
        submission: event.detail.submission.data,
        checkValidity: event.detail.checkValidity,
        beforeSubmit: event.detail.beforeSubmit,
      });
    }
  }

  componentDidMount(): void {
    document.addEventListener(submissionChangeEvent, this.submissionChangedEventHandler as EventListener);
  }

  componentWillUnmount(): void {
    document.removeEventListener(submissionChangeEvent, this.submissionChangedEventHandler as EventListener);
  }

  async saveTask() {
    const { selectedTaskUpdateStatus, onTaskSaved } = this.props;
    const { id, name, clusterId, processInstanceId, processInstanceBusinessKey, processDefinitionName } = this.props.task;
    const { submission, beforeSubmit } = this.state;
    const analyticsInstance = Analytics.getInstance();
    const errorMessageBeforeSubmit = formatMessageAsString({ id: 'task.save.before.submit.error' }, { taskName: name });

    if (processDefinitionName && window._paq) {
      window._paq.push(['trackEvent', 'Action', 'Save Task as draft', processDefinitionName]);
    }

    this.setState({ isSaving: true });

    if (beforeSubmit) {
      const errors = await beforeSubmit(true);

      if (!errors || errors.length > 0) {
        this.setState({ isSaving: false });
        return alertUtils.publishAlertDanger('Error', errorMessageBeforeSubmit);
      }
    }

    this.props
      .saveTask(submission, id!, clusterId!)
      .then(() => {
        const isSuccess = selectedTaskUpdateStatus !== 'error';

        if (analyticsInstance) {
          let saveDraftTransaction;
          saveDraftTransaction = Analytics.getCurrentTransaction();
          saveDraftTransaction.name = Analytics.constants.userInteraction.events.clickSaveTaskButton;
          saveDraftTransaction.type = Analytics.constants.transactions.type.customInteractions;
          Analytics.endTransaction(saveDraftTransaction);
        }

        if (isSuccess && processInstanceBusinessKey) {
          if (isValidUUID(processInstanceBusinessKey)) {
            this.handleSuccessfulAlert(name, processDefinitionName, undefined, 'save');
          } else {
            this.handleSuccessfulAlert(name, processDefinitionName, processInstanceBusinessKey, 'save');
          }
          onTaskSaved({
            processInstanceId: processInstanceId!,
            taskId: id!,
            successful: isSuccess,
          });
        }
      })
      .catch()
      .finally(() => {
        this.setState({ isSaving: false });
      });
  }

  async completeTask() {
    const { id, name, clusterId, processInstanceId, processDefinitionName, processInstanceBusinessKey } = this.props.task;
    const { selectedTaskUpdateStatus, onTaskCompleted } = this.props;
    const { submission, checkValidity, beforeSubmit } = this.state;
    const analyticsInstance = Analytics.getInstance();
    const errorMessage = formatMessageAsString({ id: 'task.complete.after.submit.error' }, { taskName: name });
    const errorMessageBeforeSubmit = formatMessageAsString({ id: 'task.complete.before.submit.error' }, { taskName: name });

    if (processDefinitionName && window._paq) {
      window._paq.push(['trackEvent', 'Action', 'Complete task', processDefinitionName]);
    }

    const isValidForm = checkValidity && checkValidity();

    if (!isValidForm) {
      return alertUtils.publishAlertDanger('Error', errorMessage);
    }

    this.setState({ isSubmitting: true });
    if (beforeSubmit) {
      const errors = await beforeSubmit(false);

      if (!errors || errors.length > 0) {
        this.setState({ isSubmitting: false });
        return alertUtils.publishAlertDanger('Error', errorMessageBeforeSubmit);
      }
    }

    this.props
      .completeTask(submission, id!, clusterId!)
      .then(() => {
        const isSuccess = selectedTaskUpdateStatus !== 'error';

        if (analyticsInstance) {
          let completeTaskTransaction;
          completeTaskTransaction = Analytics.getCurrentTransaction();
          completeTaskTransaction.name = Analytics.constants.userInteraction.events.clickCompleteTaskButton;
          completeTaskTransaction.type = Analytics.constants.transactions.type.customInteractions;
          Analytics.endTransaction(completeTaskTransaction);
        }

        if (isSuccess && processInstanceBusinessKey) {
          if (isValidUUID(processInstanceBusinessKey)) {
            this.handleSuccessfulAlert(name, processDefinitionName, undefined, 'complete');
          } else {
            this.handleSuccessfulAlert(name, processDefinitionName, processInstanceBusinessKey, 'complete');
          }
          onTaskCompleted({
            processInstanceId: processInstanceId!,
            taskId: id!,
            successful: isSuccess,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  }

  handleSuccessfulAlert(taskName: string | undefined, processName: string | undefined, businessKey: string | undefined, action: string) {
    const getTranslationId = () => {
      if (action === 'complete') {
        return isEmpty(businessKey) ? { id: 'task.completed.without.business.key' } : { id: 'task.completed.with.business.key' };
      }
      return isEmpty(businessKey) ? { id: 'task.saved.without.business.key' } : { id: 'task.saved.with.business.key' };
    };
    const message = formatMessageAsString(getTranslationId(), { taskName, processName, businessKey });
    return alertUtils.publishAlertSuccess('Success', message);
  }

  public render(): JSX.Element | null {
    const task = this.props.task;
    const { isSubmitting, isSaving } = this.state;

    if (task.completed || !task.form || !task.form.spec) return null;

    return (
      <div id='task-actions'>
        {!task.viewerMode && (
          <button
            id='save-task-draft-button'
            name='save-task-draft-button'
            disabled={isSubmitting || isSaving}
            className='btn btn-lg btn-discreet-secondary mr-2'
            onClick={this.saveTask}>
            <div className='d-flex align-items-center'>
              <i className='icon icon-sm mr-1'>save</i>
              <span>{formatMessage({ id: 'task.saveTask' })}</span>
            </div>
          </button>
        )}
        {!task.viewerMode && (
          <button
            id='complete-task-button'
            name='complete-task-button'
            disabled={isSubmitting || isSaving}
            className='btn btn-lg btn-discreet-success'
            onClick={this.completeTask}>
            <CompleteButtonTypography isSubmitting={isSubmitting} />
          </button>
        )}
      </div>
    );
  }
}

export default connect(
  { completeTask, saveTask },
  STORE_PROPERTIES_KEYS
)(TaskActionsCompleteComponent);
