import { h, Component } from 'preact';
import { IWidgetConfigurationContext, WithCss } from '@sgwt-widget/core';

import connect from '../../common/state/connect';
import { BpmnDiagramStateInterface } from '../../common/state/store';
import { loadDiagramForProcess, updateAccessToken } from '../../common/state/actions-task';
import { BUS_ACCESS_TOKEN } from '../../common/state/actions';
import * as diagramCss from '../../common/sgworkflow-bpmn-diagram.less';
import { ApiCallStatus } from '../../common/models';
// @ts-ignore
import NavigatedViewer from 'bpmn-js/lib/NavigatedViewer';
import { ActivityState, IncidentState, InstanceState, ProcessDefinitionModelData } from '../../../projects/workspaces-api-axios';
// @ts-ignore
import i18n from '../../common/intl/intl.service.js';

const {
  service: { formatMessage },
} = i18n;

export interface IProps {
  processDefinitionId: string;
  processInstanceId?: string;
  engineId?: string;
  processDiagramLoadingStatus?: ApiCallStatus;
  processModel?: ProcessDefinitionModelData;
  instanceState?: InstanceState;
  accessToken?: string;
  updateAccessToken: (accessToken?: string) => {};
}

interface IState {
  isModalOpen: boolean;
  bpmnDiagramComponentId?: string;
}

const STORE_PROPERTIES_KEYS: (keyof BpmnDiagramStateInterface)[] = [
  'accessToken',
  'processInstanceId',
  'processDefinitionId',
  'processDiagramLoadingStatus',
  'processModel',
  'instanceState',
];

class SgWorkflowBpmnDiagramComponent extends Component<IProps, IState> {
  public context!: IWidgetConfigurationContext;
  public static displayName = 'SgWorkflowBpmnDiagramComponent';
  private tokenSubscription: any;

  constructor() {
    super();
    this.setState({
      isModalOpen: false,
    });
  }

  componentWillMount() {
    this.setState({
      isModalOpen: false,
      bpmnDiagramComponentId: 'bpmn-diagram-component-scheme-' + this.props.processInstanceId,
    });
    this.subscribeToSGConnectBusMessages();
  }

  componentWillUnmount() {
    this.unsubscribeFromSGConnectBusMessages();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.processDiagramLoadingStatus === 'loading' && this.props.processDiagramLoadingStatus === 'loaded') {
      this.displayDiagram();
    }
  }

  subscribeToSGConnectBusMessages() {
    // SGConnect : We start the subscription to the corresponding topic.
    this.tokenSubscription = this.context.widgetConfiguration.bus.subscribe(BUS_ACCESS_TOKEN, (token?: string) => {
      // This callback is called at the subscription and every time the token is changed.
      if (token) {
        this.props.updateAccessToken(token);
      }
    });
  }

  unsubscribeFromSGConnectBusMessages() {
    // SGConnect: We need to unsubscribe!
    if (this.tokenSubscription) {
      this.context.widgetConfiguration.bus.unsubscribe(this.tokenSubscription);
      this.tokenSubscription = null;
    }
  }

  isNoProcessLoadInProgress(): boolean {
    return this.props.processDiagramLoadingStatus !== 'loading' && !!this.props.processInstanceId;
  }

  async toggleModal() {
    const { processInstanceId, processDefinitionId, engineId } = this.props;
    const visibility: boolean = !this.state.isModalOpen;

    if (visibility && window._paq) {
      window._paq.push(['trackEvent', 'Action', 'Show diagram']);
    }

    this.setState({
      isModalOpen: visibility,
    });

    if (this.isNoProcessLoadInProgress() && visibility) {
      await loadDiagramForProcess(processInstanceId, processDefinitionId, engineId);
    }
  }

  displayDiagram() {
    if (this.state.bpmnDiagramComponentId && this.props.processDiagramLoadingStatus === 'loaded') {
      const modelNode = document.getElementById(this.state.bpmnDiagramComponentId);
      const diagramAlreadyAddInDom: boolean = modelNode != null && modelNode.hasChildNodes();

      if (this.props.processModel && !diagramAlreadyAddInDom) {
        const viewer: NavigatedViewer = new NavigatedViewer();
        const diagramXML: string = this.props.processModel.processModelData!!;
        viewer.attachTo(`#${this.state.bpmnDiagramComponentId}`);

        viewer.importXML(diagramXML, (err: any) => {
          if (err) {
            console.error('an error occurred during diagram rendering');
            console.log(err);
          } else {
            const canvas = viewer.get('canvas');
            const elementRegistry = viewer.get('elementRegistry');

            this.appendStateMarkersOnDiagram(canvas, elementRegistry);
          }
        });
      }
    }
  }

  appendStateMarkersOnDiagram(canvas: any, elementRegistry: any) {
    const instanceState = this.props.instanceState;

    if (instanceState) {
      this.appendActivityMarkersOnDiagram(canvas, instanceState.activities);
      this.appendIncidentMarkersOnDiagram(elementRegistry._elements, instanceState.incidents);
    }
  }

  appendActivityMarkersOnDiagram(canvas: any, activities?: ActivityState[]) {
    if (activities) {
      activities.forEach((activity) => {
        if (activity.status === 'running') {
          canvas.addMarker(activity.ref, 'highlight-in-progress');
        } else if (activity.status === 'canceled') {
          canvas.addMarker(activity.ref, 'highlight-canceled');
        } else {
          canvas.addMarker(activity.ref, 'highlight');
        }
      });
    }
  }

  appendIncidentMarkersOnDiagram(elements: any, incidents?: IncidentState[]) {
    if (incidents) {
      incidents.forEach((incident) => {
        // @ts-ignore
        let parentElement = elements[incident.ref!!];

        if (parentElement) {
          let gfx = parentElement.gfx as SVGGElement;
          gfx.appendChild(this.drawCircle(gfx));
          gfx.appendChild(this.drawText(gfx, String(incident.count!!)));
        }
      });
    }
  }

  drawCircle(gfx: SVGGElement): Node {
    let shape = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    shape.setAttributeNS(null, 'cx', '12');
    shape.setAttributeNS(null, 'cy', String(gfx.getBBox().height - 10));
    shape.setAttributeNS(null, 'r', '10');
    shape.setAttributeNS(null, 'stroke', '#140808');
    shape.setAttributeNS(null, 'stroke-width', '2px');
    shape.setAttributeNS(null, 'fill', '#b94a48');

    return shape;
  }

  drawText(gfx: SVGGElement, count: string): Node {
    let textBox = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    if (count.length == 1) {
      textBox.setAttributeNS(null, 'x', '9');
    } else {
      textBox.setAttributeNS(null, 'x', '5');
    }
    textBox.setAttributeNS(null, 'y', String(gfx.getBBox().height - 11));
    textBox.setAttributeNS(null, 'text-length', String(count.length));
    textBox.setAttributeNS(null, 'color', 'white');
    textBox.setAttributeNS(null, 'fill', 'white');
    textBox.setAttributeNS(null, 'font-size', '12px');

    let textNode = document.createTextNode(count);
    textBox.appendChild(textNode);

    return textBox;
  }

  render(): JSX.Element | null {
    return (
      <WithCss styles={diagramCss.toString()}>
        <div id={this.state.bpmnDiagramComponentId + '-container'} className='bpmn-diagram-component'>
          <div
            className={
              !this.state.isModalOpen ? 'btn btn-discreet-primary btn-infos mr-2' : 'bpmn-diagram-component-display-button diagram-element-hide'
            }
            id='show-diagram'
            onClick={this.toggleModal.bind(this)}>
            <i class='icon icon-md' title={formatMessage({ id: 'task.show.diagram' })} alt='Icon Diagram'>
              account_tree
            </i>
          </div>
          <div className={this.state.isModalOpen ? 'bpmn-diagram-component-container' : 'bpmn-diagram-component-container diagram-element-hide'}>
            <div className='bpmn-diagram-component-popin'>
              {this.props.processDiagramLoadingStatus === 'loading' && (
                <div className='spinner-grow ml-5 bpmn-diagram-component-loading' role='status'>
                  {formatMessage({ id: 'task.load.diagram' })}
                </div>
              )}
              {this.props.processDiagramLoadingStatus === 'error' && (
                <div className='info-error'>
                  <i className='icon icon-md danger spacing-mt-4 mb-4'>info_outline</i>
                  <span className='danger'> {formatMessage({ id: 'task.load.diagram.error' })}</span>
                </div>
              )}
              <div className='bpmn-diagram-component-scheme'>
                <div className='bpmn-diagram-component-close' onClick={this.toggleModal.bind(this)}>
                  <i className='fa fa-2x fa-times' title={formatMessage({ id: 'task.hide.diagram' })} alt='Cross icon' />
                </div>
                <div id={this.state.bpmnDiagramComponentId} className='bpmn-diagram-component-scheme'></div>
              </div>
            </div>
          </div>
        </div>
      </WithCss>
    );
  }
}

export default connect(
  { updateAccessToken },
  STORE_PROPERTIES_KEYS
)(SgWorkflowBpmnDiagramComponent);
