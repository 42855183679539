import { SGWorkflowConfig } from '../config';
import { CandidateUserApiFactory, CandidateUser } from '../../../projects/workspaces-api-axios';

import { alertUtils } from './actions-alerts';
import store from './store';
import { getErrorMessage } from '../utility/utility';

export const getGroupMember = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  groupName: string,
  engineId: string
) => {
  if (groupName && accessToken) {
    try {
      const options = { headers: { Authorization: accessToken } };
      const config = { accessToken: accessToken };

      const groupApi = CandidateUserApiFactory(config, sgWorkflowConfig.workspaceApiBasePath, undefined);
      const groupApiResponse = await groupApi.getUsersByGroup(groupName, engineId, options);
      const groupMembers = groupApiResponse.data as Array<CandidateUser>;

      let groupMembersString = '';
      groupMembers.forEach((member: CandidateUser) => {
        if (member) {
          if (member.id! && member.id!.includes('@')) return (groupMembersString += member.id + ';');
        }
      });

      let groupMembersMap: Map<string, string> = store.getState().groupMembers ? store.getState().groupMembers : new Map<string, string>();
      groupMembersMap.set(groupName, groupMembersString);

      return {
        groupMembers: groupMembersMap,
      };
    } catch (err) {
      alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
    }
  }

  return {};
};
