"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const store = _interopRequireDefault(require("../state/store"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const dqhub = function dqhub(formio) {

  var accessToken = store.default.getState().accessToken;
  var sgWorkflowConfig = store.default.getState().sgWorkflowConfig;
  var task = store.default.getState().selectedLoadedTask;

  var dqhubUploadAction = require('../state/action-dqhub').uploadFile;
  var dqhubDownloadAction = require('../state/action-dqhub').downloadFile;
  var dqhubIncidentIdFinder = require('../state/action-dqhub').extractIncidentIdFromTask;

  const _nativePromiseOnly = _interopRequireDefault(require("native-promise-only"));

  return {
    uploadFile: function uploadFile(file, fileName, dir, progressCallback, url, options) {
      const incidentId = dqhubIncidentIdFinder({task: task, incidentIdVariableName: dir});
      return dqhubUploadAction({accessToken: accessToken, sgWorkflowConfig: sgWorkflowConfig, file: file, taskId: file.taskId, incidentId: incidentId});
    },
    downloadFile: function downloadFile(file, options) {

      return new _nativePromiseOnly.default(function (resolve, reject) {
        var pre = new XMLHttpRequest();

        pre.onerror = function (err) {
          err.networkError = true;
          reject(err);
        };

        pre.onabort = reject;

        pre.onload = function () {
          if (pre.status >= 200 && pre.status < 300) {
            const reader = new FileReader();
            reader.onload = function (event) {
              // convert to Base64 storage to return content directly
              const localFile = {...file, storage: 'base64', url: event.target.result};
              return resolve(localFile);
            };

            reader.onerror = function (err) {
              return reject(err);
            };

            reader.readAsDataURL(pre.response);
          } else {
            reject('Unable to get the file');
          }
        };

        pre.open('GET', "".concat(store.default.getState().sgWorkflowConfig.dqhubApiBasePath, `/v1/dataquality/incident/${file.incidentId}/file/${file.name}`));
        pre.setRequestHeader('Accept', 'application/octet-stream');
        pre.setRequestHeader('Authorization', store.default.getState().accessToken);
        pre.responseType = 'blob';

        pre.send();
      });

      // FixMe: The dedicated action doesn't work. Issue with file encoding
      // return dqhubDownloadAction({accessToken: accessToken, sgWorkflowConfig: sgWorkflowConfig, file: file});
    },
  };
};

dqhub.title = 'DqHub Incident Attachment';
const _default = dqhub;
exports.default = _default;
