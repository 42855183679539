import { h } from 'preact';
import * as moment from 'moment';
import 'moment/min/locales';
import * as _ from 'lodash';

// @ts-ignore
import i18n from '../../common/intl/intl.service.js';
import { Task } from '../../../projects/tasks-api-axios';

const userInfo = _.get(window, 'SGWTWidgetConfiguration.bus.lastValues["sg-connect.user-info"]');

moment.locale('en');
if (userInfo && userInfo['preferred_language']) {
  userInfo['preferred_language'] ? moment.locale(userInfo['preferred_language']) : moment.locale('en');
}

interface Props {
  task: Task;
  hideTitleId?: string | boolean | undefined;
}

function TaskDetails({ task, hideTitleId }: Props) {
  const {
    service: { formatMessage },
  } = i18n;

  return (
    <div class='flex-between align-items-start mb-3 mt-2'>
      <div>
        <h4 class='mb-1'>
          {task.processDefinitionName}{' '}
          {!hideTitleId && (
            <small id='show-title-id' class='text-secondary text-large'>
              ({task.processInstanceBusinessKey})
            </small>
          )}
        </h4>
        <p class='text-large mb-0'>{task.name}</p>
      </div>

      <div class='d-flex align-items-start pr-2'>
        {task.due && (
          <p class='text-secondary font-weight-medium mr-3 mb-0'>
            {formatMessage({ id: 'task.due' })} {moment(task.due).format('DD MMM YYYY')} -
            {moment(task.due).isBefore(moment(), 'second') ? (
              <span class='text-danger'> {moment(task.due).fromNow()}</span>
            ) : moment(task.due).diff(moment(), 'days') < 2 ? (
              <span class='text-warning'> {moment(task.due).fromNow()}</span>
            ) : (
              <span class='text-primary'> {moment(task.due).fromNow()}</span>
            )}
          </p>
        )}
        {task.completed ? (
          task.deleteReason !== 'deleted' ? (
            <div class='pb-1 badge badge-pill badge-discreet-success'>{formatMessage({ id: 'task.completed' })}</div>
          ) : (
            <div class='pb-1 badge badge-pill badge-discreet-primary'>{formatMessage({ id: 'task.cancelled' })}</div>
          )
        ) : (
          <div class='pb-1 badge badge-pill badge-discreet-info'>{formatMessage({ id: 'task.inProgress' })}</div>
        )}
      </div>
    </div>
  );
}

export default TaskDetails;
