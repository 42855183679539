import { Component, createRef, h } from 'preact';

interface Props {
  processName: string;
  instanceId: string;
  engineId: string;
  isModal: boolean;
  actionModal?: () => void;
  setMessageCount: (count: number) => void;
}

class ProcessCommentsWidgetWrapper extends Component<Props> {
  constructor() {
    super();
  }

  widgetRef = createRef<HTMLElement | undefined>();

  triggerSetMessageCount(evt: any) {
    this.props.setMessageCount(evt.detail);
  }

  componentDidMount = () => {
    if (this.widgetRef.current) {
      if (this.props.actionModal) {
        this.widgetRef.current.addEventListener('action-modal', this.props.actionModal);
      }
      this.widgetRef.current.addEventListener('set-message-count', this.triggerSetMessageCount.bind(this));
    }
  };

  componentWillUnMount = () => {
    if (this.widgetRef.current) {
      if (this.props.actionModal) {
        this.widgetRef.current.removeEventListener('action-modal', this.props.actionModal);
      }
      this.widgetRef.current.removeEventListener('set-message-count', this.triggerSetMessageCount.bind(this));
    }
  };

  render() {
    return (
      <process-comments-widget
        ref={this.widgetRef}
        process-name={this.props.processName}
        instance-id={this.props.instanceId}
        engine-id={this.props.engineId}
        is-modal={this.props.isModal}
      />
    );
  }
}

export default ProcessCommentsWidgetWrapper;
