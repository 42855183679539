import { Component, h } from 'preact';
import _ = require('lodash');

// @ts-ignore
import i18n from '../../common/intl/intl.service.js';

// @ts-ignore
const s3sg = require('../../common/storage/s3sg');
// @ts-ignore
const dqhub = require('../../common/storage/dqhub');
// @ts-ignore
const legacy = require('../../common/storage/legacy');

export interface IProps {
  task?: any;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sg-workflow-form-renderer': any;
    }
  }
}

interface IState {}

const {
  service: { formatMessage },
} = i18n;

class FormioForm extends Component<IProps, IState> {
  public static displayName = 'FormioForm';

  // TODO remove on v5 upgrade
  shouldComponentUpdate(nextProps: IProps) {
    // Avoids refreshing the component unless task changes
    if (nextProps.task.id !== this.props.task.id) return true;
    return false;
  }

  public render(): JSX.Element | null {
    const userInfo = _.get(window, 'SGWTWidgetConfiguration.bus.lastValues')['sg-connect.user-info'];
    const userLanguage = (userInfo && userInfo.preferred_language) || undefined;

    const { task } = this.props;

    if (task && task.form && task.form.type === 'formio') {
      const formioSubmission = { data: { ...task!.formSubmission } };
      const formioOptions = { ...task!.form!.options, readOnly: task.completed, language: userLanguage };

      return (
        <sg-workflow-form-renderer
          id='renderer'
          form={task!.form!.spec}
          submission={formioSubmission}
          options={formioOptions}
          storageprovider={{
            s3sg: s3sg.default,
            dqhub: dqhub.default,
            legacy: legacy.default,
          }}
          importwidgets={false}
        />
      );
    }

    return (
      <div class='container my-5 text-center text-secondary' style='max-width:320px'>
        <i class='icon icon-xl d-inline-block pt-5 mb-2'>list</i>
        <h4>{formatMessage({ id: 'task.withNoForm' })}</h4>
      </div>
    );
  }
}

export default FormioForm;
