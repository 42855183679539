import { Component, h } from 'preact';
import { IWidgetConfigurationProps, withWidgetConfiguration, WithCss } from '@sgwt-widget/core';

import { Task } from '../../../projects/tasks-api-axios';
import connect from '../../common/state/connect';
import { assignTask, unAssignTask, addCandidate, getWorkspaceInfo, resetSelectedTaskUpdateStatus, loadTask } from '../../common/state/actions-task';
import { ApiCallStatus, AssignOption } from '../../common/models';
import store, { TaskStateInterface } from '../../common/state/store';
import * as assigncss from '../../common/sgworkflow-assign-modal.less';
import { alertUtils } from '../../common/state/actions-alerts';
import { TaskAssignmentChangedEventDetail } from './SgWorkflowTaskFormComponent';
// @ts-ignore
import i18n from '../../common/intl/intl.service.js';
interface IProps {
  selectedLoadedTask: Task;
  loadTask: () => {};
  onTaskAssignmentChanged: (detail: TaskAssignmentChangedEventDetail) => {};
  addCandidate: (selectedTaskId: string, selectedTaskEngineId: string, candidate: any) => Promise<any>;
  assignTask: (selectedTaskId: string, selectedTaskEngineId: string, assignee: any) => Promise<any>;
  unAssignTask: (selectedTaskId: string, selectedTaskEngineId: string) => Promise<any>;
  resetSelectedTaskUpdateStatus: () => {};
  selectedTaskUpdateStatus: ApiCallStatus;
  actionModal: () => {};
}
// STORE_PROPERTIES_KEYS register props keys we want to inject from the store
const STORE_PROPERTIES_KEYS: (keyof TaskStateInterface)[] = ['selectedTaskUpdateStatus', 'selectedLoadedTask'];

interface IState {
  assignee: string | undefined;
  showAssignModal: boolean;
  assignOption?: AssignOption;
  isOptionHidden: boolean;
}

const {
  service: { formatMessage, formatMessageAsString },
} = i18n;

const TaskActionsAssignComponent = withWidgetConfiguration(
  class extends Component<IProps & IWidgetConfigurationProps, IState> {
    constructor() {
      super();
      this.state = {
        showAssignModal: false,
        assignOption: undefined,
        assignee: undefined,
        isOptionHidden: true,
      };
    }

    assignContactPicker: any;
    candidateContactPicker: any;

    componentDidUpdate() {
      if (this.props.selectedTaskUpdateStatus === 'loaded' || this.props.selectedTaskUpdateStatus === 'error') {
        this.props.resetSelectedTaskUpdateStatus();
      }
      if (this.assignContactPicker) {
        this.assignContactPicker.addEventListener('ic-contact-single-picker--changed', (event: any) => this.getAssigneeFromContactPicker(event));
      }

      if (this.candidateContactPicker) {
        this.candidateContactPicker.addEventListener('ic-contact-single-picker--changed', (event: any) => this.getCandidateFromContactPicker(event));
      }
    }

    componentDidMount() {
      const {
        user,
        selectedLoadedTask: { tenantId },
      } = store.getState();
      getWorkspaceInfo(tenantId).then(() => {
        const {
          selectedTaskWorkspaceInformation: { managers },
        } = store.getState();
        if (managers.length) {
          const userFound = managers.find((manager: any) => manager === user);
          if (userFound) {
            this.setState({ isOptionHidden: false });
          }
        }
      });
    }

    assignTask() {
      if (this.state.assignOption === 'claim') {
        this.props.assignTask(this.props.selectedLoadedTask.id!, this.props.selectedLoadedTask.clusterId!, null).then(() => {
          const { selectedTaskUpdateStatus } = this.props;
          if (selectedTaskUpdateStatus !== 'error' && store.getState().assignee !== undefined) {
            alertUtils.publishAlertSuccess('Success', formatMessageAsString({ id: 'task.user.assign' }));
            this.onTaskAssignmentChanged();
          }
          this.props.actionModal();
        });
      } else if (this.state.assignOption === 'assign') {
        this.props.assignTask(this.props.selectedLoadedTask.id!, this.props.selectedLoadedTask.clusterId!, this.state.assignee).then(() => {
          const { selectedTaskUpdateStatus } = this.props;
          if (selectedTaskUpdateStatus !== 'error' && store.getState().assignee !== undefined) {
            alertUtils.publishAlertSuccess('Success', formatMessageAsString({ id: 'task.assignee' }, { assignee: store.getState().assignee }));
            this.onTaskAssignmentChanged();
          }
          this.props.actionModal();
        });
      } else if (this.state.assignOption === 'assignAnyone') {
        this.props.addCandidate(this.props.selectedLoadedTask.id!, this.props.selectedLoadedTask.clusterId!, this.state.assignee).then(() => {
          const { selectedTaskUpdateStatus } = this.props;
          if (selectedTaskUpdateStatus !== 'error') {
            this.props.assignTask(this.props.selectedLoadedTask.id!, this.props.selectedLoadedTask.clusterId!, this.state.assignee).then(() => {
              const { selectedTaskUpdateStatus } = this.props;
              if (selectedTaskUpdateStatus !== 'error' && store.getState().assignee !== undefined) {
                alertUtils.publishAlertSuccess('Success', formatMessageAsString({ id: 'task.assignee' }, { assignee: store.getState().assignee }));
                this.onTaskAssignmentChanged();
              }
              this.props.actionModal();
            });
          }
        });
      } else {
        this.props.unAssignTask(this.props.selectedLoadedTask.id!, this.props.selectedLoadedTask.clusterId!).then(() => {
          const { selectedTaskUpdateStatus } = this.props;
          if (selectedTaskUpdateStatus !== 'error') {
            alertUtils.publishAlertSuccess('Success', formatMessageAsString({ id: 'task.user.unassign' }, { assignee: store.getState().assignee }));
            this.onTaskAssignmentChanged();
          }
          this.props.actionModal();
        });
      }
    }

    onTaskAssignmentChanged() {
      if (this.props.onTaskAssignmentChanged) {
        const completeEvent = {
          assignee: store.getState().assignee,
          successful: true,
        };
        this.props.onTaskAssignmentChanged(completeEvent);
      }
    }

    setAssignOption(assignOption: AssignOption) {
      this.setState({ assignOption });
    }

    getAssigneeFromContactPicker(event: any) {
      const email = event.detail.contact.email || event.detail.email;
      if (email) return this.setState({ assignee: email });
    }

    getCandidateFromContactPicker(event: any) {
      const email = event.detail.contact.email || event.detail.email;
      if (email) return this.setState({ assignee: email });
    }

    public render(): JSX.Element | any {
      return (
        <WithCss styles={assigncss}>
          <div class='modal-dialog' role='document'>
            <div class='modal-content shadow-max'>
              <div class='modal-header'>
                <h3 class='modal-title' id='exampleModalLabel1'>
                  {formatMessage({ id: 'task.assignTask' })}
                </h3>
                <button type='button' class='close icon' data-dismiss='modal' aria-label='Close' onClick={this.props.actionModal}>
                  close
                </button>
              </div>
              <div class='modal-body'>
                <div class='form-group'>
                  <div class='custom-control custom-radio mb-4'>
                    <input
                      id='unassign'
                      name='unassign'
                      type='radio'
                      checked={this.state.assignOption === 'unassign'}
                      onChange={() => this.setAssignOption('unassign')}
                      class='custom-control-input'
                    />
                    <label class='custom-control-label' for='unassign'>
                      {formatMessage({ id: 'task.unassign' })}
                    </label>
                  </div>
                  <div class='custom-control custom-radio py-4 separator'>
                    <input
                      id='claim'
                      name='claim'
                      type='radio'
                      class='custom-control-input'
                      checked={this.state.assignOption === 'claim'}
                      onChange={() => this.setAssignOption('claim')}
                    />
                    <label class='custom-control-label' for='claim'>
                      {formatMessage({ id: 'task.assign.to.me' })}
                    </label>
                  </div>
                  <div class='custom-control custom-radio py-4'>
                    <input
                      id='assign'
                      name='assign'
                      type='radio'
                      class='custom-control-input'
                      checked={this.state.assignOption === 'assign'}
                      onChange={() => this.setAssignOption('assign')}
                    />
                    <label class='custom-control-label' for='assign'>
                      {formatMessage({ id: 'task.assign.to.candidate.user' })}
                    </label>
                  </div>
                  {this.state.assignOption === 'assign' && (
                    <div class='mt-4'>
                      <div class='contact-picker-info mb-2'>{formatMessage({ id: 'task.select.contact' })}.</div>
                      <ic-contact-single-picker
                        max-result-count='10'
                        internal-only='true'
                        ref={(elem: any) => (this.assignContactPicker = elem)}
                        sso-v2='true'
                        ic-contact-single-picker--changed={this.getAssigneeFromContactPicker.bind(this)}
                      />
                    </div>
                  )}
                  {!this.state.isOptionHidden && (
                    <div class='custom-control custom-radio pt-4 separator' style={{ borderBottom: 'none' }}>
                      <input
                        id='assignToAnyone'
                        name='assignToAnyone'
                        type='radio'
                        class='custom-control-input'
                        checked={this.state.assignOption === 'assignAnyone'}
                        onChange={() => this.setAssignOption('assignAnyone')}
                      />
                      <label class='custom-control-label' for='assignToAnyone'>
                        {formatMessage({ id: 'task.assign.to.anyone' })}
                      </label>
                    </div>
                  )}
                  {this.state.assignOption === 'assignAnyone' && (
                    <div class='mt-4'>
                      <div class='contact-picker-info mb-2'>{formatMessage({ id: 'task.select.contact' })}.</div>
                      <ic-contact-single-picker
                        max-result-count='10'
                        internal-only='true'
                        ref={(elem: any) => (this.candidateContactPicker = elem)}
                        sso-v2='true'
                        ic-contact-single-picker--changed={this.getCandidateFromContactPicker.bind(this)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div class='modal-footer'>
                <button type='button' class='btn btn-lg btn-flat-secondary' data-dismiss='modal' onClick={this.props.actionModal}>
                  {formatMessage({ id: 'task.cancel' })}
                </button>
                <button type='button' class='btn btn-lg btn-primary' data-dismiss='modal' onClick={this.assignTask.bind(this)}>
                  {formatMessage({ id: 'task.assign' })}
                </button>
              </div>
            </div>
          </div>
        </WithCss>
      );
    }
  }
);

export default connect(
  { loadTask, assignTask, addCandidate, unAssignTask, resetSelectedTaskUpdateStatus },
  STORE_PROPERTIES_KEYS
)(TaskActionsAssignComponent);
