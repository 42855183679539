import { Component, h } from 'preact';
import { IWidgetConfigurationContext, IWidgetConfigurationProps, WithCss, withWidgetConfiguration } from '@sgwt-widget/core';
import * as moment from 'moment';
import 'moment/min/locales';
import * as _ from 'lodash';

import { Task } from '../../../projects/tasks-api-axios';
import { EmptyMessageWithAutoLoad, EmptyNoAccess, EmptyWithError, EmptyWithNoTasks, Loading } from './Empty';
import FormioForm from './FormioForm';
import { ApiCallStatus, SelectedTaskInfos, TimelineEventType } from '../../common/models';
import connect from '../../common/state/connect';
import {
  exportTaskAsHtml,
  getWorkspaceInfo,
  loadTask,
  updateAccessToken,
  updateUserId,
  updateSelectedLoadedTask,
} from '../../common/state/actions-task';
import { exportProcessAsHtml } from '../../common/state/actions-process';
import { countMessages } from '../../common/state/action-message';
import {
  BUS_ACCESS_TOKEN,
  BUS_SELECTED_TASK_AND_CLUSTER,
  BUS_TASK_UPDATED,
  BUS_USER_CONNECTION,
  BUS_USER_INFO,
  updateSelectedTaskEngineId,
  updateSelectedTaskId,
  updateSelectedTaskLoadingStatus,
} from '../../common/state/actions';
import TaskActionsCompleteComponent from './TaskActionsCompleteComponent';
import store, { StoreStateInterface } from '../../common/state/store';
import * as taskCss from '../../common/sgworkflow-task.less';
import TaskActionsAssignComponent from './TaskActionsAssignComponent';
import { alertUtils } from '../../common/state/actions-alerts';
import { getGroupMember } from '../../common/state/action-candidates';
import { Workspace } from '../../../projects/workspaces-api-axios';
import { getErrorMessage } from '../../common/utility/utility';
import { getAuthorizedEngineIds } from '../../common/state/action-authorization';
import { CONFIG } from '../../common/config';
// @ts-ignore
import i18n from '../../common/intl/intl.service.js';
import TaskDetails from './TaskDetails';
import TaskSecondaryActions from './TaskSecondaryActions';
import ProcessCommentsWidgetWrapper from './ProcessCommentWidgetWrapper';

const userInfo = _.get(window, 'SGWTWidgetConfiguration.bus.lastValues["sg-connect.user-info"]');

moment.locale('en');
if (userInfo && userInfo['preferred_language']) {
  userInfo['preferred_language'] ? moment.locale(userInfo['preferred_language']) : moment.locale('en');
}

type TaskTab = 'complete' | 'comments' | 'attachments';

export interface TaskSavedEventDetail {
  processInstanceId: string;
  taskId: string;
  successful: boolean;
}

export interface TaskCompletedEventDetail {
  processInstanceId: string;
  taskId: string;
  successful: boolean;
}

export interface TaskAssignmentChangedEventDetail {
  assignee?: string;
  successful: boolean;
}

export interface IProps {
  useBus: boolean;
  engineId?: string;
  taskId?: string;
  selectedLoadedTask?: Task;
  selectedTaskUpdateStatus: ApiCallStatus;
  updateSelectedLoadedTask: (selectedLoadedTask?: Task) => {};
  selectedTaskLoadingStatus?: ApiCallStatus;
  selectedTaskWorkspaceInformation: Workspace;
  updateSelectedTaskId: (selectedTaskId?: string) => {};
  updateSelectedTaskEngineId: (selectedTaskEngineId?: string) => {};
  updateSelectedTaskLoadingStatus: (selectedTaskLoadingStatus?: ApiCallStatus) => {};
  loadTask: any;
  onTaskAssignmentChanged: (detail: TaskAssignmentChangedEventDetail) => boolean;
  onTaskSaved: (detail: TaskSavedEventDetail) => boolean;
  onTaskCompleted: (detail: TaskCompletedEventDetail) => boolean;
  getWorkspaceInfo: (tenantId?: string) => {};
  accessToken?: string;
  updateUserId: (userId?: string) => {};
  updateAccessToken: (accessToken?: string) => {};
  getAuthorizedEngineIds(): () => {};
  selectedTaskType?: TimelineEventType;
  messageCount: number;
  groupMembers: Map<string, string>;
  getGroupMember: (groupName?: string, engineId?: string) => {};
  exportTaskAsHtml: (taskId: string, taskName: string, clusterId: string, processDefinitionId?: string) => {};
  exportProcessAsHtml: (processInstanceId: string, engineId: string, realmType: 'sglan' | 'sgmarkets') => {};
  countMessages: (processInstanceId: string, engineId: string) => {};
  hideTitleId?: boolean | string | undefined;
  hideProcessHistoryButton?: boolean | string | undefined;
  hideContactButton?: boolean | string | undefined;
  hideCommentsButton?: boolean | string | undefined;
  hideDiagramButton?: boolean | string | undefined;
  hideExportMenu?: boolean | string | undefined;
}
// STORE_PROPERTIES_KEYS register props keys we want to inject from the store
const STORE_PROPERTIES_KEYS: (keyof StoreStateInterface)[] = [
  'selectedLoadedTask',
  'selectedTaskLoadingStatus',
  'selectedTaskUpdateStatus',
  'selectedTaskId',
  'selectedTaskWorkspaceInformation',
  'accessToken',
  'groupMembers',
  'selectedTaskType',
  'messageCount',
];

interface IState {
  selectedTab?: TaskTab;
  displayTaskExport?: boolean;
  displayProcessExport?: boolean;
  selectedExportAction?: string;
  showAssignModal?: boolean;
  showMessageModal?: boolean;
  showTeamPopover?: boolean;
  isUserInLan?: boolean;
  htmlTaskExportAvailable?: boolean;
  htmlProcessExportAvailable?: boolean;
  isMessageCountLoading?: boolean;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      // @ts-ignore
      'ic-contact-single-picker': icContactSinglePicker;
    }
  }
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'process-comments-widget': any;
    }
  }
}

const reloadTaskRenderer = 'reload-task-renderer';

const {
  service: { formatMessage },
} = i18n;

const SgWorkflowTaskFormComponent = withWidgetConfiguration(
  class extends Component<IProps & IWidgetConfigurationProps, IState> {
    context!: IWidgetConfigurationContext;
    tokenSubscription: any;
    userSubscription: any;
    userInfoSubscription: any;
    conf = CONFIG.get();
    selectedTaskInfosSubscription: any;
    popover: any;
    static displayName = 'SgWorkflowTaskFormComponent';

    constructor() {
      super();
      this.state = {
        selectedTab: 'complete',
        displayTaskExport: false,
        displayProcessExport: false,
        showAssignModal: false,
        showMessageModal: false,
        showTeamPopover: false,
        isUserInLan: false,
        htmlTaskExportAvailable: true,
        htmlProcessExportAvailable: true,
        isMessageCountLoading: false,
      };

      this.toggleMessageModal = this.toggleMessageModal.bind(this);
      this.toggleModal = this.toggleModal.bind(this);
      this.setMessageCount = this.setMessageCount.bind(this);
      this.getMessageCount = this.getMessageCount.bind(this);
    }

    setMessageCount(newCount: number) {
      store.setState({ messageCount: newCount });
    }

    componentWillMount() {
      const { useBus, taskId, engineId } = this.props;
      this.subscribeToSGConnectBusMessages();
      this.props.getAuthorizedEngineIds();

      if (useBus !== false) {
        this.subscribeToSelectedTaskInfosBusMessages();
      } else if (taskId && engineId) {
        this.updateTaskInfo({
          selectedTaskId: taskId,
          selectedEngineId: engineId,
        });
      }

      document.addEventListener(reloadTaskRenderer, () => this.props.loadTask());
    }

    componentWillUnmount() {
      store.setState({
        tasksLoadingStatus: 'no-tasks',
        selectedLoadedTask: undefined,
        selectedTaskId: undefined,
        selectedTaskEngineId: undefined,
        selectedTaskLoadingStatus: 'loading',
      });

      this.unsubscribeFromSGConnectBusMessages();
      this.unsubscribeFromSelectedTaskInfosBusMessages();
      document.removeEventListener(reloadTaskRenderer, () => this.props.loadTask());
    }

    componentDidUpdate(previousProps: Readonly<IProps & IWidgetConfigurationProps>): void {
      const task = this.getSelectedTask();
      // Initiate a Message count but only after get all the required parameters
      const shouldUpdateMessages = task && !this.state.isMessageCountLoading;

      if (task && shouldUpdateMessages) {
        this.setState({ isMessageCountLoading: true });
        this.getMessageCount(task.processInstanceId!, task.clusterId!);
      }

      const { useBus, taskId, engineId } = this.props;
      if (useBus !== previousProps.useBus) {
        if (useBus !== false) {
          this.subscribeToSelectedTaskInfosBusMessages();
        } else {
          this.unsubscribeFromSelectedTaskInfosBusMessages();
        }
      }

      if (taskId !== previousProps.taskId) {
        this.setState({ showTeamPopover: false });

        // Setup message for loading next task info
        this.setState({ isMessageCountLoading: false });

        if (useBus === false && taskId && engineId) {
          this.updateTaskInfo({
            selectedTaskId: taskId,
            selectedEngineId: engineId,
          });
        }
      }
    }

    createCandidateMailTemplate(candidate: string, task: Task): string {
      if (!candidate.includes('@')) {
        this.props.getGroupMember(candidate, task.clusterId);
        candidate = this.props.groupMembers && this.props.groupMembers.get(candidate) ? this.props.groupMembers.get(candidate)! : '';
      }
      const subject = '[SGWorkflow - ' + task.processDefinitionName + '] - ' + task.processInstanceBusinessKey + ' - ' + task.name;
      const body =
        'Hello, %0D%0A %0D%0A' +
        'Task link: ' +
        window.location.href +
        '%0D%0A %0D%0A----%0D%0APlease include your message here.%0D%0A %0D%0A %0D%0ARegards,';
      return 'mailto:' + candidate + '?subject=' + subject + '&body=' + body;
    }

    getTaskTeams(task: Task): String {
      let team = '';
      if (task.candidateGroups) {
        task.candidateGroups.map((group) => (team += group + ', '));
      }

      if (task.candidateUsers) {
        task.candidateUsers.map((user) => (team += user + ', '));
      }

      return team.slice(0, -2);
    }

    getAdminOfWorkspace(task: Task) {
      this.props.getWorkspaceInfo(task.tenantId!);
    }

    toggleModal() {
      this.setState({ showAssignModal: !this.state.showAssignModal });
    }

    toggleMessageModal() {
      const task = this.getSelectedTask();
      this.setState({ showMessageModal: !this.state.showMessageModal });

      if (window._paq && task && task.processDefinitionName) {
        window._paq.push(['trackEvent', 'Action', 'Show comments', task.processDefinitionName]);
      }
    }

    async exportTaskAsHtml() {
      const { exportTaskAsHtml } = this.props;
      const task = this.getSelectedTask();

      if (window._paq && task && task.processDefinitionName) {
        window._paq.push(['trackEvent', 'Action', 'Export task', task.processDefinitionName]);
      }

      if (task) {
        this.setState({ htmlTaskExportAvailable: false });

        await exportTaskAsHtml(task.id!, task.name, task.clusterId!, task.processDefinitionId);

        this.setState({ htmlTaskExportAvailable: true });
      }
    }

    async exportProcessAsHtml() {
      const { exportProcessAsHtml, useBus } = this.props;
      const task = this.getSelectedTask();

      if (window._paq && task && task.processDefinitionName) {
        window._paq.push(['trackEvent', 'Action', 'Export whole process', task.processDefinitionName]);
      }

      if (task && task.processInstanceId) {
        this.setState({ htmlProcessExportAvailable: false });

        await exportProcessAsHtml(task.processInstanceId, task.clusterId!, this.state.isUserInLan ? 'sglan' : 'sgmarkets');

        this.setState({ htmlProcessExportAvailable: true });
      } else if (!useBus) {
        console.error('Process instance Id is not defined!');
      }
    }

    getMessageCount(processInstanceId: string, engineId: string) {
      this.props.countMessages(processInstanceId, engineId);
    }

    subscribeToSGConnectBusMessages() {
      // SGConnect : We start the subscription to the corresponding topic.
      this.tokenSubscription = this.context.widgetConfiguration.bus.subscribe(BUS_ACCESS_TOKEN, (token?: string) => {
        // This callback is called at the subscription and every time the token is changed.
        if (token) this.props.updateAccessToken(token);
      });

      this.userSubscription = this.context.widgetConfiguration.bus.subscribe(BUS_USER_CONNECTION, (userConnection?: any) => {
        // This callback is called at the subscription and every time the token is changed.
        if (userConnection) {
          if (CONFIG.get().apmEnabled) {
            // // Analytics.load();
          }
          this.props.updateUserId(userConnection.mail);
        }
      });

      this.userInfoSubscription = this.context.widgetConfiguration.bus.subscribe(BUS_USER_INFO, (userInfoSubscription?: any) => {
        if (userInfoSubscription) {
          this.setState({
            isUserInLan: userInfoSubscription.origin_network === 'LAN',
          });
        }
      });
    }

    updateTaskInfo(selectedTaskInfos: SelectedTaskInfos) {
      this.props.updateSelectedTaskId(selectedTaskInfos.selectedTaskId);
      this.props.updateSelectedTaskEngineId(selectedTaskInfos.selectedEngineId);
      this.props.loadTask();
    }

    subscribeToSelectedTaskInfosBusMessages() {
      this.selectedTaskInfosSubscription = this.context.widgetConfiguration.bus.subscribe(
        BUS_SELECTED_TASK_AND_CLUSTER,
        (selectedTaskInfos?: SelectedTaskInfos) => {
          const { tasksLoadingStatus } = store.getState();
          if (selectedTaskInfos) {
            const shouldLoadTask = selectedTaskInfos.selectedTaskId && selectedTaskInfos.selectedEngineId && tasksLoadingStatus !== 'loading';

            if (shouldLoadTask) {
              this.updateTaskInfo(selectedTaskInfos);
            }

            if (selectedTaskInfos.messageSummaryTaskForm) {
              this.props.updateSelectedTaskLoadingStatus(selectedTaskInfos.messageSummaryTaskForm);
            }
          }
        }
      );
    }

    unsubscribeFromSelectedTaskInfosBusMessages() {
      if (this.selectedTaskInfosSubscription) {
        this.context.widgetConfiguration.bus.unsubscribe(this.selectedTaskInfosSubscription);
        this.selectedTaskInfosSubscription = null;
      }
    }

    unsubscribeFromSGConnectBusMessages() {
      // SGConnect: We need to unsubscribe!
      if (this.tokenSubscription) {
        this.context.widgetConfiguration.bus.unsubscribe(this.tokenSubscription);
        this.tokenSubscription = null;
      }
      if (this.userSubscription) {
        this.context.widgetConfiguration.bus.unsubscribe(this.userSubscription);
        this.userSubscription = null;
      }
      if (this.userInfoSubscription) {
        this.context.widgetConfiguration.bus.unsubscribe(this.userInfoSubscription);
        this.userInfoSubscription = null;
      }
    }

    onTaskCompleted(detail: TaskCompletedEventDetail) {
      if (!this.props.onTaskCompleted || this.props.onTaskCompleted(detail)) {
        this.onTaskUpdated(detail, false);
      }
    }

    onTaskSaved(detail: TaskSavedEventDetail) {
      if (this.props.onTaskSaved(detail)) {
        this.onTaskUpdated(detail, true);
      }
    }

    onTaskAssignmentChanged(detail: TaskAssignmentChangedEventDetail) {
      if (!this.props.onTaskAssignmentChanged || this.props.onTaskAssignmentChanged(detail)) {
        this.onTaskUpdated(detail, false);
      }
    }

    async onTaskUpdated(detail: any, isSaveAction: boolean) {
      if (isSaveAction && detail.successful) {
        this.forceUpdate();
      }

      if (detail.successful && !isSaveAction) {
        this.props.loadTask();
        this.context.widgetConfiguration.bus.publish(BUS_TASK_UPDATED, true);
      }
    }

    getSelectedTask() {
      const { selectedLoadedTask } = this.props;
      return selectedLoadedTask;
    }

    getClassName() {
      return store.getState().selectedTaskUpdateStatus === 'loading' ? 'overlay' : '';
    }

    render(): JSX.Element | any {
      const {
        hideTitleId,
        hideProcessHistoryButton,
        hideContactButton,
        hideCommentsButton,
        hideDiagramButton,
        hideExportMenu,
        countMessages,
      } = this.props;
      const { selectedTaskLoadingStatus, isTaskSelected, tasksLoadingStatus, messageCount } = store.getState();
      const { isUserInLan, showAssignModal, htmlProcessExportAvailable, htmlTaskExportAvailable, showMessageModal } = this.state;

      const task = this.getSelectedTask();

      if (selectedTaskLoadingStatus === 'error') {
        return <EmptyWithError />;
      }

      if (!task) {
        if (selectedTaskLoadingStatus === 'loading') {
          return <Loading />;
        }

        if (selectedTaskLoadingStatus === 'noresult') {
          if (isTaskSelected) {
            return <EmptyNoAccess />;
          }

          if (tasksLoadingStatus === 'no-tasks') {
            return <EmptyWithNoTasks />;
          }

          return <EmptyMessageWithAutoLoad />;
        }
      }

      // TODO: Remove the double load use !isEmpty(store.getState().tasksPage) but wont work on /process
      if (task) {
        countMessages(task.processInstanceId!, task.clusterId!);

        if (!task.form || task.form.type !== 'formio') {
          store.setState({
            canDoActionOnTask: {
              canSubmit: true,
              formIsValid: true,
            },
          });

          return (
            <div className='container my-5 text-center text-secondary' style='max-width:320px'>
              <i className='icon icon-xl d-inline-block pt-5 mb-2'>list</i>
              <h4>{formatMessage({ id: 'task.withNoForm' })}</h4>
            </div>
          );
        }

        return (
          <WithCss styles={taskCss}>
            <div>
              {isUserInLan && showMessageModal && !hideCommentsButton && (
                <div className='task-modal'>
                  <ProcessCommentsWidgetWrapper
                    actionModal={() => this.setState({ showMessageModal: false })}
                    instanceId={task.processInstanceId!!}
                    processName={task.processDefinitionName!!}
                    engineId={task.clusterId!!}
                    setMessageCount={this.setMessageCount}
                    isModal={true}
                  />
                </div>
              )}

              {showAssignModal && (
                <div className='task-modal'>
                  <TaskActionsAssignComponent
                    actionModal={this.toggleModal}
                    selectedLoadedTask={task}
                    onTaskAssignmentChanged={(detail: TaskAssignmentChangedEventDetail) => this.onTaskAssignmentChanged(detail)}
                  />
                </div>
              )}
            </div>

            <div className={this.getClassName()}>
              <TaskDetails task={task} hideTitleId={hideTitleId} />
              <TaskSecondaryActions
                toggleModal={this.toggleModal.bind(this)}
                getTaskTeams={this.getTaskTeams(task)}
                createCandidateMailTemplate={(candidateGroup: any) => this.createCandidateMailTemplate(candidateGroup, task)}
                toggleMessageModal={this.toggleMessageModal}
                exportProcessAsHtml={this.exportProcessAsHtml.bind(this)}
                messageCount={messageCount}
                exportTaskAsHtml={this.exportTaskAsHtml.bind(this)}
                isUserInLan={isUserInLan}
                task={task}
                htmlTaskExportAvailable={htmlTaskExportAvailable}
                htmlProcessExportAvailable={htmlProcessExportAvailable}
                hideExportMenu={hideExportMenu}
                hideProcessHistoryButton={hideProcessHistoryButton}
                hideContactButton={hideContactButton}
                hideCommentsButton={hideCommentsButton}
                hideDiagramButton={hideDiagramButton}
              />

              <div className='mb-5'>
                <FormioForm task={task} />
              </div>

              <div id='save-complete' style='flex: 20; min-height: 0;' className='mb-2 py-2 d-flex justify-content-start border-top'>
                {!task.completed ? (
                  <TaskActionsCompleteComponent
                    task={task}
                    onTaskCompleted={(detail: TaskCompletedEventDetail) => this.onTaskCompleted(detail)}
                    onTaskSaved={(detail: TaskSavedEventDetail) => this.onTaskSaved(detail)}
                  />
                ) : (
                  <div />
                )}
              </div>
            </div>
          </WithCss>
        );
      }
    }
  }
);

export default connect(
  {
    loadTask,
    getWorkspaceInfo,
    updateAccessToken,
    updateUserId,
    getAuthorizedEngineIds,
    updateSelectedTaskId,
    updateSelectedTaskEngineId,
    updateSelectedTaskLoadingStatus,
    updateSelectedLoadedTask,
    getGroupMember,
    exportTaskAsHtml,
    exportProcessAsHtml,
    countMessages,
  },
  STORE_PROPERTIES_KEYS
)(SgWorkflowTaskFormComponent);
