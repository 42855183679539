import { h, initializeWidgetConfiguration, SgWidget, WidgetConfigurationProvider, WithGlobalCss } from '@sgwt-widget/core';
import { ComponentProps, props } from 'skatejs';
import { Provider } from 'redux-zero/preact';

import SgWorkflowTaskFormComponent from './components/SgWorkflowTaskFormComponent';
import * as taskCss from '../common/sgworkflow-task.less';
import store from '../common/state/store';
import { CONFIG } from '../common/config';
import { EVENT_TASK_ASSIGNMENT_CHANGED, EVENT_TASK_FORM_COMPLETED, EVENT_TASK_FORM_SAVED } from '../common/state/actions';
// @ts-ignore
import Analytics from '../analytics/analytics.service';

export interface ISgWorkflowTaskProps {
  user?: string;
  currenttoken?: string;
  taskid?: string;
  engineId?: string;
  listenerId?: string;
  useBus: string;
  loadStyles: string;
  hideTitleId: string;
  hideProcessHistoryButton: string;
  hideContactButton: string;
  hideCommentsButton: string;
  hideDiagramButton: string;
  hideExportMenu: string;
}

export class SgWorkflowTaskForm extends SgWidget<ISgWorkflowTaskProps> {
  public static is = 'sg-workflow-task-form';
  private listenerRef?: any;

  public static props: ComponentProps<ISgWorkflowTaskProps> = {
    user: props.string,
    currenttoken: props.string,
    taskid: props.string,
    engineId: props.string,
    listenerId: props.string,
    useBus: props.string,
    loadStyles: props.string,
    hideTitleId: props.string,
    hideProcessHistoryButton: props.string,
    hideContactButton: props.string,
    hideCommentsButton: props.string,
    hideDiagramButton: props.string,
    hideExportMenu: props.string,
  };

  public connectedCallback() {
    super.connectedCallback();
    if (Analytics.getInstance()) {
      Analytics.addAnalyticsMetadata(SgWorkflowTaskForm.is, this.widgetConfiguration);
    }
  }

  addWidgetLink() {
    const sgWorkflowConfig = CONFIG.get();
    const linkScriptId = `stylesheet-src-formiojs`;
    if (!document.getElementById(linkScriptId)) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.id = linkScriptId;
      link.type = 'text/css';
      link.href = `${sgWorkflowConfig.formioCssBasePath}/formio.full.css`;
      document.head.appendChild(link);
    } else {
      console.log(`Script ${linkScriptId} is already in the document`);
    }
  }

  public render() {
    this.initWidgetConfiguration();
    const {
      loadStyles,
      hideTitleId,
      hideProcessHistoryButton,
      hideContactButton,
      hideCommentsButton,
      hideDiagramButton,
      hideExportMenu,
      taskid,
    } = this.props;

    if (!loadStyles || loadStyles !== 'false') {
      this.addWidgetLink();
    }

    return (
      <WidgetConfigurationProvider widgetConfiguration={this.widgetConfiguration}>
        <WithGlobalCss styles={taskCss}>
          <Provider store={store}>
            <SgWorkflowTaskFormComponent
              useBus={this.props.useBus !== 'false'}
              engineId={this.props.engineId}
              taskId={taskid}
              onTaskCompleted={(detail: any) => this.propagateEvent(EVENT_TASK_FORM_COMPLETED, detail)}
              onTaskSaved={(detail: any) => this.propagateEvent(EVENT_TASK_FORM_SAVED, detail)}
              onTaskAssignmentChanged={(detail: any) => this.propagateEvent(EVENT_TASK_ASSIGNMENT_CHANGED, detail)}
              hideTitleId={hideTitleId === 'true'}
              hideProcessHistoryButton={hideProcessHistoryButton === 'true'}
              hideContactButton={hideContactButton === 'true'}
              hideCommentsButton={hideCommentsButton === 'true'}
              hideDiagramButton={hideDiagramButton === 'true'}
              hideExportMenu={hideExportMenu === 'true'}
            />
          </Provider>
        </WithGlobalCss>
      </WidgetConfigurationProvider>
    );
  }

  propagateEvent(evtKey: string, detail: any) {
    if (this.listenerRef) {
      const evt = new CustomEvent(evtKey, {
        bubbles: true,
        cancelable: true,
        detail: detail,
      });
      return this.listenerRef.dispatchEvent(evt);
    }
    return true;
  }

  private initWidgetConfiguration() {
    const initState = {
      sgWorkflowConfig: CONFIG.get(),
    };
    if (this.props.user) {
      // @ts-ignore
      initState.user = this.props.user;
    }
    if (this.props.currenttoken) {
      // @ts-ignore
      initState.accessToken = this.props.currenttoken;
    }
    if (this.props.listenerId) {
      this.listenerRef = document.getElementById(this.props.listenerId);
    }
    store.setState(initState);
  }
}

initializeWidgetConfiguration(SgWorkflowTaskForm, { neverUseShadowDOM: true });
customElements.define(SgWorkflowTaskForm.is, SgWorkflowTaskForm);
