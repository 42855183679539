"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const _nativePromiseOnly = _interopRequireDefault(require("native-promise-only"));
const store = _interopRequireDefault(require("../state/store"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const legacy = function legacy(formio) {
  return {
    uploadFile: function uploadFile(file, fileName, dir, progressCallback, url, options) {

      return new _nativePromiseOnly.default(function (resolve, reject) {
        // Send the pre response to sign the upload.
        var pre = new XMLHttpRequest(); // This only fires on a network error.

        pre.onerror = function (err) {
          err.networkError = true;
          reject(err);
        };

        pre.onabort = reject;

        pre.onload = function () {
          const response = JSON.parse(pre.response);
          if (pre.status >= 200 && pre.status < 300) {
            return resolve({
              storage: 'legacy',
              name: fileName,
              taskId: store.default.getState().selectedLoadedTask.id,
              contentId: response.id,
              size: file.size,
              type: file.type
            });

          } else {
            reject('Unable to upload the file');
          }

        };

        pre.open('POST', "".concat(store.default.getState().sgWorkflowConfig.sgbpmApiBasePath, `/v1/tasks/${store.default.getState().selectedLoadedTask.id}/content/raw`));
        pre.setRequestHeader('Accept', 'application/json');
        pre.setRequestHeader('Authorization', store.default.getState().accessToken);


        const fd = new FormData();
        fd.append('name', fileName);
        fd.append('dir', dir);
        fd.append('file', file);

        pre.send(fd);
      });
    },
    downloadFile: function downloadFile(file) {
      //const headers = new http.HttpHeaders().set('Accept', 'application/octet-stream');

      return new _nativePromiseOnly.default(function (resolve, reject) {
        var pre = new XMLHttpRequest();

        pre.onerror = function (err) {
          err.networkError = true;
          reject(err);
        };

        pre.onabort = reject;

        pre.onload = function () {
          if (pre.status >= 200 && pre.status < 300) {
            const reader = new FileReader();
            reader.onload = function (event) {
              // convert to Base64 storage to return content directly
              const localFile = {...file, storage: 'base64', url: event.target.result};
              return resolve(localFile);
            };

            reader.onerror = function (err) {
              return reject(err);
            };

            reader.readAsDataURL(pre.response);
          } else {
            reject('Unable to get the file');
          }
        };

        pre.open('GET', "".concat(store.default.getState().sgWorkflowConfig.sgbpmApiBasePath, `/v1/tasks/${file.taskId}/content/${file.contentId}/raw`));
        pre.setRequestHeader('Accept', 'application/octet-stream');
        pre.setRequestHeader('Authorization', store.default.getState().accessToken);
        pre.responseType = 'blob';

        pre.send();
      });
    }
  };
};

legacy.title = 'Task Attachment (Deprecated)';
const _default = legacy;
exports.default = _default;
