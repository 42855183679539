"use strict";


Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const _nativePromiseOnly = _interopRequireDefault(require("native-promise-only"));

const _trim2 = _interopRequireDefault(require("lodash/trim"));

const store = _interopRequireDefault(require( "../state/store"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const trim = function trim(text) {
  return (0, _trim2.default)(text, '/');
};

const path = function path(items) {
  return items.filter(function (item) {
    return !!item;
  }).map(trim).join('/');
};

const s3sg = function s3(formio) {
  return {
    uploadFile: function uploadFile(file, fileName, dir, progressCallback) {
      return new _nativePromiseOnly.default(function (resolve, reject) {
        // Send the pre response to sign the upload.
        const pre = new XMLHttpRequest(); // This only fires on a network error.

        pre.onerror = function (err) {
          err.networkError = true;
          reject(err);
        };

        pre.onabort = reject;

        pre.onload = function () {
          if (pre.status >= 200 && pre.status < 300) {
            const response = JSON.parse(pre.response); // Send the file with data.

            const xhr = new XMLHttpRequest();

            if (typeof progressCallback === 'function') {
              xhr.upload.onprogress = progressCallback;
            }

            response.data.fileName = fileName;
            response.data.key = path([response.data.key, dir, fileName]); // Fire on network error.

            xhr.onerror = function (err) {
              err.networkError = true;
              reject(err);
            };

            xhr.onload = function () {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve({
                  storage: 's3',
                  name: fileName,
                  bucket: response.bucket,
                  key: response.data.key,
                  url: path([response.url, response.data.key]),
                  acl: response.data.acl,
                  size: file.size,
                  type: file.type
                });
              } else {
                reject(xhr.response || 'Unable to upload file');
              }
            };

            xhr.onabort = reject;

            if (response.signed) {
              xhr.open('PUT', response.signed);
              xhr.setRequestHeader('Content-Type', file.type);
              xhr.send(file);
            } else {
              const fd = new FormData();

              for (let key in response.data) {
                fd.append(key, response.data[key]);
              }

              fd.append('file', file);
              xhr.open('POST', "".concat(store.default.getState().sgWorkflowConfig.taskApiBasePath, `/tasks/${store.default.getState().selectedLoadedTask.id}/storage/s3/raw?clusterId=`, store.default.getState().selectedLoadedTask.clusterId));
              xhr.setRequestHeader('Authorization', store.default.getState().accessToken);
              xhr.send(fd);
            }
          } else {
            reject(pre.response || 'Unable to sign file');
          }
        };

        pre.open('POST', "".concat(store.default.getState().sgWorkflowConfig.taskApiBasePath, `/tasks/${store.default.getState().selectedLoadedTask.id}/storage/s3?clusterId=`, store.default.getState().selectedLoadedTask.clusterId));
        pre.setRequestHeader('Accept', 'application/json');
        pre.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        pre.setRequestHeader('Authorization', store.default.getState().accessToken);

        pre.send(JSON.stringify({
          name: path([dir, fileName]),
          size: file.size,
          type: file.type
        }));
      });
    },
    downloadFile: function downloadFile(file) {
      if (file.acl !== 'public-read') {
        return new _nativePromiseOnly.default(function (resolve, reject) {
          // Send the pre response to sign the upload.
          const pre = new XMLHttpRequest(); // This only fires on a network error.

          pre.onerror = function (err) {
            err.networkError = true;
            reject(err);
          };

          pre.onabort = reject;

          pre.onload = function () {
            if (pre.status >= 200 && pre.status < 300) {
              const reader = new FileReader();
              reader.onload = function (event) {
                // convert to Base64 storage to return content directly
                const localFile = {...file, storage: 'base64', url: event.target.result};
                return resolve(localFile);
              };

              reader.onerror = function (err) {
                return reject(err);
              };

              reader.readAsDataURL(pre.response);
            } else {
              reject('Unable to get the file');
            }
          };

          pre.open('GET', "".concat(store.default.getState().sgWorkflowConfig.taskApiBasePath, `/tasks/${store.default.getState().selectedLoadedTask.id}/storage/s3/raw?key=`)
                                    .concat(trim(encodeURIComponent(file.key))
                                    .concat("&clusterId=", store.default.getState().selectedLoadedTask.clusterId)));
          pre.setRequestHeader('Accept', 'application/octet-stream');
          pre.setRequestHeader('Authorization', store.default.getState().accessToken);
          pre.responseType = 'blob';

          pre.send();
        });
      } else {
        return _nativePromiseOnly.default.resolve(file);
      }
    }
  };
};

s3sg.title = 'SG Internal Storage (S3 compatible)';
const _default = s3sg;
exports.default = _default;
