import { h, Component } from 'preact';

import { SelectedTaskInfos } from '../../common/models';
import { BUS_SELECTED_TASK_AND_CLUSTER } from '../../common/state/actions';
import store from '../../common/state/store';
// @ts-ignore
import i18n from '../../common/intl/intl.service.js';

const widgetConfiguration = (window as any).SGWTWidgetConfiguration;

function handleReload() {
  const reloadTaskRenderer = 'reload-task-renderer';
  const customEvent = new CustomEvent(reloadTaskRenderer, { bubbles: true });
  document.dispatchEvent(customEvent);
}

async function handleRouteChange(selectedTaskId: string) {
  const reRouteEvent = 'add-url-task-id';
  const customEvent = new CustomEvent(reRouteEvent, { bubbles: true, detail: { selectedTaskId } });
  document.dispatchEvent(customEvent);
  return Promise.resolve(true);
}

const {
  service: { formatMessage },
} = i18n;

export function Loading(): JSX.Element {
  return (
    <div className='d-flex flex-column align-items-center' style={{ paddingTop: 200 }}>
      <div className='spinner spinner-xl mb-1' role='status'></div>
      <p>{formatMessage({ id: 'task.loadingTask' })}</p>
    </div>
  );
}

export function EmptyWithError(): JSX.Element {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center text-center' style={{ paddingTop: 200 }}>
      <div className='bg-light-alpha-lg mb-4' style={{ padding: 15 }}>
        <em className='icon icon-xl text-dark-alpha-xxl bg-black-alpha-sm mb-3' style={{ height: 35, width: 35, borderRadius: '100%', padding: 5 }}>
          block
        </em>
        <h4 className='text-dark-alpha-xxl font-weight-bold'>{formatMessage({ id: 'task.loadError.primary' })}</h4>
      </div>
      <h4 className='text-dark-alpha-xxl'>{formatMessage({ id: 'task.loadError.secondary' })}</h4>
    </div>
  );
}

export function EmptyWithNoTasks(): JSX.Element {
  return (
    <div class='container my-5 text-center text-secondary' style='max-width:320px'>
      <i class='icon icon-xl d-inline-block pt-5 mb-2'>list</i>
      <h4>{formatMessage({ id: 'task.emptyNoTask.primary' })}</h4>
      <p>{formatMessage({ id: 'task.emptyNoTask.secondary' })}</p>
    </div>
  );
}

export function EmptyNoAccess(): JSX.Element {
  return (
    <div className='mt-200 d-flex flex-column align-items-center justify-content-center text-center'>
      <div>
        <em className='icon icon-lg mb-1 text-primary'>error_outline</em>
        <h4 className='text-primary'>{formatMessage({ id: 'task.emptyMessage.primary' })}</h4>
        <p className='text-secondary mb-4'>
          {formatMessage(
            { id: 'task.emptyMessage.secondary' },
            { team: formatMessage({ id: 'task.myTeamTasks' }), bold: (team: string) => <b>{team}</b> }
          )}
        </p>
      </div>
    </div>
  );
}

export class EmptyMessageWithAutoLoad extends Component {
  componentDidMount() {
    const { firstTask } = store.getState();

    const selectedTaskInfos: SelectedTaskInfos = {
      selectedTaskId: firstTask.id,
      selectedEngineId: firstTask.clusterId,
      messageSummaryTaskForm: 'loaded',
    };

    // Force the task to show
    widgetConfiguration.bus.publish(BUS_SELECTED_TASK_AND_CLUSTER, selectedTaskInfos);
    handleRouteChange(firstTask.id).then((res) => {
      if (res) return handleReload();
    });
  }

  render(): JSX.Element {
    return (
      <div className='mt-200 d-flex flex-column align-items-center justify-content-center text-center'>
        <div>
          <em className='icon icon-lg mb-1 text-primary'>error_outline</em>
          <h4 className='text-primary'>{formatMessage({ id: 'task.emptyNotSelected.primary' })}</h4>
          <p className='text-secondary mb-4'>{formatMessage({ id: 'task.emptyNotSelected.secondary' })}</p>
        </div>
      </div>
    );
  }
}
