import { h } from 'preact';
import * as moment from 'moment';
import 'moment/min/locales';
import * as _ from 'lodash';

// @ts-ignore
import i18n from '../../common/intl/intl.service.js';
import SgWorkflowBpmnDiagramComponent from '../../sg-workflow-bpmn-diagram/components/SgWorkflowBpmnDiagramComponent';
import { createTaskHelpMailTemplate, getFullNameFromEmail } from '../../common/utility/utility';
import { CONFIG } from '../../common/config';

const userInfo = _.get(window, 'SGWTWidgetConfiguration.bus.lastValues["sg-connect.user-info"]');

moment.locale('en');
if (userInfo && userInfo['preferred_language']) {
  userInfo['preferred_language'] ? moment.locale(userInfo['preferred_language']) : moment.locale('en');
}

interface Props {
  task: any;
  exportTaskAsHtml: any;
  toggleModal: any;
  getTaskTeams: any;
  createCandidateMailTemplate: any;
  toggleMessageModal: any;
  exportProcessAsHtml: any;
  htmlTaskExportAvailable: any;
  htmlProcessExportAvailable: any;
  hideExportMenu: any;
  messageCount: any;
  isUserInLan: any;
  hideProcessHistoryButton: any;
  hideContactButton: any;
  hideCommentsButton: any;
  hideDiagramButton: any;
}

function TaskSecondaryActions({
  exportTaskAsHtml,
  toggleModal,
  getTaskTeams,
  createCandidateMailTemplate,
  toggleMessageModal,
  exportProcessAsHtml,
  htmlTaskExportAvailable,
  htmlProcessExportAvailable,
  hideExportMenu,
  messageCount,
  task,
  isUserInLan,
  hideProcessHistoryButton,
  hideContactButton,
  hideCommentsButton,
  hideDiagramButton,
}: Props): JSX.Element {
  const {
    service: { formatMessage },
  } = i18n;

  function getMessageCount() {
    if (!messageCount) return;
    return messageCount > 0 ? `(${messageCount})` : '';
  }

  function getAssignee() {
    if (!task.assignee) return formatMessage({ id: 'task.unassigned' });
    return getFullNameFromEmail(task.assignee);
  }

  function trackProcessHistoryClickEvent() {
    if (window._paq) {
      window._paq.push(['trackEvent', 'Action', 'Display process history', task.processDefinitionName]);
    }
  }

  return (
    <div id='navbar-form' style=''>
      <div className='d-flex align-items-center mb-3' style='flex-wrap: wrap; justify-content: space-between '>
        <div className='d-flex'>
          <div className='mr-4'>
            <p className='font-weight-medium text-secondary mb-0'>{formatMessage({ id: 'task.creation.date' })}</p>
            <p className='mb-0'>{moment(task.created).format('DD MMM YYYY')}</p>
          </div>

          <div className='mr-4'>
            <p className={task.assignee ? 'font-weight-medium text-secondary mb-0' : 'font-weight-medium text-secondary mb-0'}>
              {formatMessage({ id: 'task.assign.to' })}
            </p>
            <p className='d-flex mb-0'>
              <p className='task-assignee mb-0'>{getAssignee()}</p>
              {!task.completed && isUserInLan && !task.viewerMode && (
                <i onClick={toggleModal} className='icon icon-md fa-lg icon-over ml-2'>
                  create
                </i>
              )}
            </p>
          </div>

          <div className='' style='max-width: 200px; overflow: hidden; height:50px;'>
            <p className='font-weight-medium text-secondary mb-0'>{formatMessage({ id: 'task.teams' })}</p>
            <div className=' '>
              <div
                className='mb-0 text-truncate'
                type='button'
                id='dropdownMenuTeam'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'>
                {getTaskTeams}
              </div>
              <div className='dropdown-menu' aria-labelledby='dropdownMenuTeam'>
                {task.candidateGroups && task.candidateGroups!.length > 0 && (
                  <div>
                    <h3 className='popover-header'>{formatMessage({ id: 'task.candidate.groups' })}</h3>
                    <ul className='list-group'>
                      {task.candidateGroups!.map((candidateGroup: string) => (
                        <a
                          id='mailToCandidateGroup'
                          className='list-group-item bg-white pt-0 pl-3 task-team'
                          title={candidateGroup}
                          href={createCandidateMailTemplate(candidateGroup)}>
                          {candidateGroup}
                        </a>
                      ))}
                    </ul>
                  </div>
                )}
                {task.candidateUsers && task.candidateUsers!.length > 0 && (
                  <div>
                    <h3 className='popover-header'>{formatMessage({ id: 'task.candidate.users' })}</h3>
                    <ul className='list-group'>
                      {task.candidateUsers!.map((candidateUser: any) => {
                        return candidateUser.includes('@') ? (
                          <a
                            id='mailToCandidateUser'
                            className='list-group-item bg-white pt-0 pl-3 task-team'
                            title={candidateUser}
                            href={createCandidateMailTemplate(candidateUser)}>
                            {candidateUser}
                          </a>
                        ) : (
                          <li className='list-group-item bg-white pt-0 pl-0'>{candidateUser}</li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex'>
          {!window.location.href.includes('/processes/') && !hideProcessHistoryButton && isUserInLan && (
            <a
              className='d-flex btn btn-discreet-primary align-items-center mr-2'
              id='display-process-history'
              onClick={trackProcessHistoryClickEvent}
              href={`${CONFIG.get().uiBasePath}/processes/${task.processInstanceId}?taskId=${task.id}`}
              target='_blank'
              title={formatMessage({ id: 'task.go.process.history' })}>
              {' '}
              <div className='process-history-link mr-3'>{formatMessage({ id: 'task.process.history' })}</div>
              <i className='icon'>open_in_new</i>
            </a>
          )}
          {!hideContactButton && !task.viewerMode && (
            <button
              id='task-help-button'
              name='task-help-button'
              type='button'
              className='d-flex btn btn-discreet-primary align-items-center mr-2'
              onClick={() => createTaskHelpMailTemplate(task)}
              data-toggle='tooltip'
              data-placement='top'
              title={formatMessage({ id: 'task.complete.help' })}
              data-original-title={formatMessage({ id: 'task.complete.help' })}>
              <i className='icon'>mail</i>
              <div className='ml-3 process-history-link'>{formatMessage({ id: 'task.contact.support' })}</div>
            </button>
          )}

          {isUserInLan && !hideCommentsButton && (
            <button
              type='button'
              class='btn btn-primary'
              id='show-comments-button'
              data-toggle='modal'
              data-target='#commentModal'
              className='d-flex btn btn-discreet-primary btn-infos align-items-center mr-2 pr-1'
              onClick={toggleMessageModal}>
              <i class='icon icon-md' title={formatMessage({ id: 'task.add.comment' })} alt='Icon Add Comment'>
                chat_bubble
              </i>
              <div className='ml-3' style='display: flex; align-self: flex-start;'>
                {getMessageCount()}
              </div>
            </button>
          )}

          {isUserInLan && (
            <div className='d-flex'>
              {!hideDiagramButton && (
                <div id='show-diagram-button'>
                  <SgWorkflowBpmnDiagramComponent
                    processDefinitionId={task.processDefinitionId}
                    processInstanceId={task.processInstanceId}
                    engineId={task.clusterId}
                  />
                </div>
              )}
              {!hideExportMenu && (
                <div id='show-export-menu'>
                  <div className='dropdown'>
                    <button className='btn btn-discreet-primary' type='' aria-haspopup='true' aria-expanded='false'>
                      <i className='icon icon-md'>more_vert</i>
                    </button>
                    <div class='dropdown-content'>
                      <div
                        id='export-whole-process-html'
                        name='export-whole-process-html'
                        disabled={!htmlProcessExportAvailable}
                        onClick={exportProcessAsHtml}>
                        <span className='pl-2'>{formatMessage({ id: 'task.export.process' })}</span>
                      </div>
                      <div id='export-task-as-html' disabled={!htmlTaskExportAvailable} onClick={exportTaskAsHtml}>
                        <span className='pl-2'>{formatMessage({ id: 'task.export.single.task' })}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TaskSecondaryActions;
